import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     tap({
  //       next: () => null,
  //       error: (err: HttpErrorResponse) => {
  //         if ([401].includes(err.status)) {
  //           console.log('relogin');
  //           this.router.navigate(['/']);
  //         }
  //         const error = err.error?.message || err.status;
  //         return throwError(error);
  //       },
  //     })
  //   );
  // }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          sessionStorage.clear();
          this.router.navigate(['/']);
        }
        const error = err.error?.message || err.statusText;
        return throwError(() => err);
      })
    );
  }
}
