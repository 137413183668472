import {importProvidersFrom, Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {DialogModule} from "@angular/cdk/dialog";
import {OverlayModule} from "@angular/cdk/overlay";
import {NgxOtpInputModule} from "ngx-otp-input";
import {ColorPickerModule} from "ngx-color-picker";
import {Observable} from "rxjs";
import {environment} from "@environment/environment";

import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {ErrorInterceptor} from "./interceptor/error.interceptor";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {SecurityModule} from "./guards/security.module";
import { CommonConfirmDialogComponent } from './component/common-confirm-dialog/common-confirm-dialog.component';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const baseUrl = environment.baseUrl;
    //const baseUrl = ''
    const reqPath = req.url;
    let apiUrl = req.clone({url: `${baseUrl}${reqPath}`});
    return next.handle(apiUrl);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CommonConfirmDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OverlayModule,
    DialogModule,
    NgxOtpInputModule,
    AngularSvgIconModule.forRoot(),
    ColorPickerModule,
    SocialLoginModule,
    FormsModule,
    SecurityModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbLogin),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLogin)
          }
        ],
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor, // Register the ErrorInterceptor
      multi: true,
    },

    importProvidersFrom(NgSelectModule)
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
