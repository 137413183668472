export const environment = {
  production: false,
  baseUrl: '',
  version: '0.0.1',
  client: 'industry-network',
  secret: 'industry2network',
  googleKey:'AIzaSyBlvEjVjLQNB0nhZzOYQ6dfoXR5NTN0AZg',
  fbLogin:'2668462043453049',
  googleLogin:'666899652236-10vevqpn3ukvn544qug70kfgbo481fqv.apps.googleusercontent.com'
};
